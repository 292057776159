import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonListHeader,
    IonItem,
    IonInput,
    IonLabel,
    IonToggle,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonRange,
    IonButton,
    IonTextarea,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Page.css';
import firebaseApp from '../firebase';
import { toast } from '../components/toast';
const fbDb = firebaseApp.firestore().collection('municipality');

const Settings = () => {
    // const [ fireban, setFireban ] = useState(false);
    // const [ riskLevel, setRiskLevel ] = useState(3);
    const [town, setTown] = useState(null);

    const municipality = 'Port Sanilac';
    const [blockedRadius, setBlockedRadius] = useState({ size: 75, unit: 'Meters' });

    const getTown = () => {
        fbDb.doc(municipality)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    setTown(data);
                    if (data.blockedRadius) {
                        setBlockedRadius({
                            size: data.blockedRadius.size,
                            unit: data.blockedRadius.unit,
                        });
                    }
                } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!');
                }
            })
            .catch(function (error) {
                console.log('Error getting document:', error);
            });
    };
    const setTownDoc = () => {
        fbDb.doc(municipality)
            .update({
                address: town.address ? town.address : null,
                blurb: town.blurb ? town.blurb : null,
                blurbTitle: town.blurbTitle ? town.blurbTitle : null,
                email: town.email ? town.email : null,
                fireban: town.fireban ? town.fireban : null,
                image: town.image ? town.image : null,
                latitude: town.latitude ? town.latitude : null,
                longitude: town.longitude ? town.longitude : null,
                phone: town.phone ? town.phone : null,
                riskLevel: town.riskLevel ? town.riskLevel : null,
                blockedRadius,
            })
            .then(() => {
                toast(`Settings for ${municipality} updated`);
            });
    };

    useEffect(() => {
        getTown();
    }, []);

    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar color='primary'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Settings</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Settings</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard mode='ios'>
                                <IonCardHeader>
                                    <IonCardSubtitle>Manage town</IonCardSubtitle>
                                    <IonCardTitle>FirePertmit.Online Settings</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonList>
                                        <IonListHeader>Town Settings</IonListHeader>
                                        {town !== null ? (
                                            <div>
                                                <IonItem>
                                                    <IonLabel>Fire ban?</IonLabel>
                                                    <IonToggle
                                                        checked={town.fireban}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                fireban: e.detail.checked,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel>Fire risk level?</IonLabel>
                                                    <IonRange
                                                        pin={true}
                                                        min={0}
                                                        max={5}
                                                        step={1}
                                                        value={town.riskLevel}
                                                        snaps={true}
                                                        color='primary'
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                riskLevel: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Town Centre (Latitude)
                                                    </IonLabel>
                                                    <IonInput
                                                        type='number'
                                                        value={town.latitude}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                latitude: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Town Centre (Longitude)
                                                    </IonLabel>
                                                    <IonInput
                                                        type='number'
                                                        value={town.longitude}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                longitude: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Health Concern Exclusion Radius
                                                    </IonLabel>
                                                    <IonInput
                                                        type='number'
                                                        placeholder='Enter Number'
                                                        value={blockedRadius.size}
                                                        onIonChange={(e) =>
                                                            setBlockedRadius({
                                                                ...blockedRadius,
                                                                size: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                {/* <IonItem>
                          <IonLabel position="stacked">Exclusion Radius Unit</IonLabel>
                          <IonSelect value={blockedRadius.unit} placeholder="Select One" onIonChange={e => setBlockedRadius({...blockedRadius, unit: e.detail.value})}>
                            <IonSelectOption value="meters">Metres</IonSelectOption>
                            <IonSelectOption value="feet">Feet</IonSelectOption>
                            <IonSelectOption value="yards">Yards</IonSelectOption>
                          </IonSelect>
                        </IonItem> */}
                                                <IonListHeader>Landing Page Settings</IonListHeader>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Department Name
                                                    </IonLabel>
                                                    <IonInput
                                                        value={town.blurbTitle}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                blurbTitle: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Department Description
                                                    </IonLabel>
                                                    <IonTextarea
                                                        auto-grow='true'
                                                        value={town.blurb}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                blurb: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Department Image
                                                    </IonLabel>
                                                    <IonInput
                                                        value={town.image}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                image: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Department Address
                                                    </IonLabel>
                                                    <IonInput
                                                        value={town.address}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                address: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Department Phone
                                                    </IonLabel>
                                                    <IonInput
                                                        value={town.phone}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                phone: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel position='stacked'>
                                                        Department Email
                                                    </IonLabel>
                                                    <IonInput
                                                        value={town.email}
                                                        onIonChange={(e) =>
                                                            setTown({
                                                                ...town,
                                                                email: e.detail.value,
                                                            })
                                                        }
                                                    />
                                                </IonItem>
                                            </div>
                                        ) : null}
                                    </IonList>
                                    <br />
                                    <IonButton expand='block' onClick={setTownDoc}>
                                        Save Settings
                                    </IonButton>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Settings;

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { toast } from './components/toast';

const firebaseConfig = {
    apiKey: 'AIzaSyCO2lSE09E6nWnxRc05Ks1oa5hR1QS1Wv8',
    authDomain: 'port-sanilac-fpo.firebaseapp.com',
    projectId: 'port-sanilac-fpo',
    storageBucket: 'port-sanilac-fpo.appspot.com',
    messagingSenderId: '684659541990',
    appId: '1:684659541990:web:1744503948e6995671b827',
    measurementId: 'G-BV19407V7B',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
export default firebaseApp;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export async function loginUser(email, password) {
    try {
        const res = await firebase.auth().signInWithEmailAndPassword(email, password);
        console.log(res);
        return true;
    } catch (error) {
        toast(error.message, 4000);
        return false;
    }
}

export function logoutUser() {
    return firebase.auth().signOut();
}
